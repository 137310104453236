<template>
  <div class="background">
    <div class="headBar">
      <div class="headBar_child">
        <div class="request"  style="cursor: pointer">提出需求</div>
        <div class="service"  style="cursor: pointer">提供服务</div>
      </div>
    </div>
    <div class="mainBody">
      <!--必填-->
      <div>
        <div class="title">
          <!--        <i></i>-->
          <p>需求内容详情</p>
        </div>
        <div class="formRequired">
          <div>
            <p>公司名称</p>
            <input type="text" v-model="this.form.companyName">
          </div>
          <div>
            <p>需求联系人</p>
            <input type="text" v-model="this.form.linkman">
          </div>
          <div>
            <p>联系电话</p>
            <input type="text" v-model="this.form.phone">
          </div>
          <div>
            <p>需求名称</p>
            <input type="text" v-model="this.form.projectName">
          </div>
          <div>
            <p>需求类别</p>
            <el-cascader :options="options" :show-all-levels="false" v-model="this.cascaderTemp" placeholder="请选择"/>
          </div>
          <div class="start">
           <div>
             <p>开始时间</p>
             <el-date-picker v-model="this.form.beginTime" type="date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" placeholder="项目开始时间" @change="timeCoculator(form.beginTime,form.endTime)"></el-date-picker>
           </div>
             <div>
               <p class="change">结束时间</p>
               <el-date-picker v-model="this.form.endTime" type="date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" placeholder="项目结束时间" @change="timeCoculator(form.beginTime,form.endTime)"></el-date-picker>
             </div>
            <div>
              <p class="change">项目周期</p>
              <input type="text" v-model="this.form.period" disabled>
              <i>天</i>
            </div>
           <div>
             <p>招募人数</p>
             <input type="text" v-model="this.form.needNum">
             <i>人</i>
           </div>
            <div>
              <p class="change">预算金额</p>
              <input type="text" v-model="this.form.budget">
              <i>元</i>
            </div>
          </div>
        </div>
      </div>
      <!--补充-->
      <div class="supplement">
        <div class="title">
          <!--<i></i>-->
          <p>项目详情</p>
        </div>
        <div class="formAddition">
          <div>
            <p>项目背景</p>
            <div>
              <input type="text" v-model="this.form.background">
            </div>
          </div>
          <div>
            <p>风格要求</p>
            <div>
              <input type="text" v-model="this.form.style">
            </div>
          </div>
          <div>
            <p>目标用户人群</p>
            <div>
              <input type="text" v-model="this.form.targetPeople">
            </div>
            <div style="margin-bottom: 55px;">
              <p>相关资料</p>
              <div>
                <input type="text" v-model="this.form.otherInformation">
              </div>
            </div>
          </div>

          <div class="standard">
            <div>标准交付</div>
            <div class="box1">
              <h1>设计交付物</h1>
              <div class="choice">
                <el-checkbox-group v-model="checkBoxTemp1">
                  <el-checkbox label="初稿方案" class="checkbox"/>
                  <el-checkbox label="终稿方案" class="checkbox"/>
                </el-checkbox-group>
              </div>
            </div>
            <div class="box1">
              <h1>中标交付物</h1>
              <div class="choice">
                <el-checkbox-group v-model="checkBoxTemp2">
                  <el-checkbox label="提案方案" class="checkbox"/>
                  <el-checkbox label="设计效果稿" class="checkbox"/>
                  <el-checkbox label="设计源文件" class="checkbox"/>
                </el-checkbox-group>
              </div>
              <h2>(最终交付物以合同内容为准)</h2>
            </div>
          </div>
          <div class="enclosure">
            <el-upload  :on-change="fileChange" :show-file-list="false">
              <p>相关附件</p>
              <button>提交附件</button>
            </el-upload>
          </div>
        </div>

      </div>

      <div class="button_box">
        <button v-show="false">提交发布</button>
        <button @click="submit">提交发布</button>
      </div>
    </div>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "RequestDetail",
  data(){
    return{
      options:[
        {
          value: '产品设计',
          label: '产品设计',
          children: [
            {
              value: '医疗设备',
              label: '医疗设备',
            },
            {
              value: '仪器设备',
              label: '仪器设备',
            },
            {
              value: '消费电子',
              label: '消费电子',
            },
            {
              value: '交通出行',
              label: '交通出行',
            },
            {
              value: '工业设备',
              label: '工业设备',
            },
            {
              value: '机器人',
              label: '机器人',
            },]
        },
        {
          value: '研发工程',
          label: '研发工程',
          children: [
            {
              value: '结构设计',
              label: '结构设计',
            },
            {
              value: '自动化设计',
              label: '自动化设计',
            },]
        },
        {
          value: '传播设计',
          label: '传播设计',
          children: [
            {
              value: '品牌vi',
              label: '品牌vi',
            },
            {
              value: '电商视觉',
              label: '电商视觉',
            },
            {
              value: '网站/APP',
              label: '网站/APP',
            },
            {
              value: '产品包装',
              label: '产品包装',
            },
            {
              value: 'Logo设计',
              label: 'Logo设计',
            },
            {
              value: '画册设计',
              label: '画册设计',
            },
            {
              value: 'UI设计',
              label: 'UI设计',
            },]
        },
        {
          value: '空间设计',
          label: '空间设计',
          children: [
            {
              value: '商业空间',
              label: '商业空间',
            },
            {
              value: '厂房改造',
              label: '厂房改造',
            },
            {
              value: '会展陈列',
              label: '会展陈列',
            },
            {
              value: '空间导视',
              label: '空间导视',
            },]
        }
      ],

      checkBoxTemp1:[],
      checkBoxTemp2:[],
      cascaderTemp:[],

      form:{
        companyName:'',
        linkman:'',
        phone:'',
        projectName:'',
        typeFirst:'',
        typeSecond:'',
        beginTime:'',
        endTime:'',
        period:'',
        needNum:'',
        budget:'',
        background:'',
        style:'',
        targetPeople:'',
        otherInformation:'',
        deliverableType:'',
        annex:'',
      },

    }
  },
  methods:{
    submit(){
      this.form.typeFirst = this.cascaderTemp[0];
      this.form.typeSecond = this.cascaderTemp[1];
      for(let i = 0;i<this.checkBoxTemp1.length; i++){
        this.form.deliverableType+= this.checkBoxTemp1[i];
        this.form.deliverableType+= ',';
      }
      for(let i = 0;i<this.checkBoxTemp2.length; i++){
        this.form.deliverableType+= this.checkBoxTemp2[i];
        this.form.deliverableType+= ',';
      }
     //将数据转化为formData格式
      formData.append('companyName',this.form.companyName.toString());
      formData.append('linkman',this.form.linkman.toString());
      formData.append('phone',this.form.phone.toString());
      formData.append('projectName',this.form.projectName.toString());
      formData.append('typeFirst',this.form.typeFirst.toString());
      formData.append('typeSecond',this.form.typeSecond.toString());
      formData.append('beginTime',this.form.beginTime.toString());
      formData.append('endTime',this.form.endTime.toString());
      formData.append('period',this.form.period.toString());
      formData.append('needNum',this.form.needNum.toString());
      formData.append('budget',this.form.budget.toString());
      formData.append('background',this.form.background.toString());
      formData.append('style',this.form.style.toString());
      formData.append('targetPeople',this.form.targetPeople.toString());
      formData.append('otherInformation',this.form.otherInformation.toString());
      formData.append('deliverableType',this.form.deliverableType.toString());

      this.$http({
        method: 'post',
        url: '/publishProject',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.$notify({
          title: '操作成功',
          message: '',
          type: 'success'
        });
        this.$router.push('/Home');
      });
    },

    fileChange (files, fileList) {
      formData.append('annex',fileList[0].raw)
    },

    timeCoculator(start,end){
      let sy = Number(start.slice(0,4));
      let sm = Number(start.slice(5,7));
      let sd = Number(start.slice(8,10));

      let ey = Number(end.slice(0,4));
      let em = Number(end.slice(5,7));
      let ed = Number(end.slice(8,10));

      this.form.period = (ey - sy)*365 + (em-sm)*30 + (ed-sd);
    },
  },
}
</script>

<style scoped>

p{
  margin-bottom: 0;
}

input{
  font-size: 24px!important;
  color:#212121!important;
  padding-left: 25px!important;
}

.change{
  width: 142px!important;
}

.background{
  height: 2382px;
  position: relative;
  background-image: url("../../images/req_bac.png");
  background-size: 100% 100%;
  padding-top: 128px;
}

.mainBody{
  margin-left: 257px;
}


.headBar{
  width: 100%;
  height: 92px;
  font-size: 32px;
  font-weight: normal;
  /*position: absolute;*/
  /*margin-top: 205px;*/
  /*margin-left: 601px;*/
  margin-bottom: 83px;
  border: 20px #3c3c3c;


}

.headBar_child{
  width: 720px;
  height: 100%;
  margin: 0 auto;


}

.headBar .request{
  float: left;
  background-color: rgb(235,66,77);
  color: #ffffff;
  width: 360px;
  height: 92px;
  line-height: 92px;
  /*display: table-cell;*/
  text-align: center;
  font-size: 32px;
  border: 2px solid rgb(235,66,77);
  border-radius: 3px;
  /*border-right: none;*/
  /*border-radius: 5px;*/
}

.headBar .service{
  float: left;
  color: #212121;
  width: 360px;
  height: 92px;
  /*display: table-cell;*/
  /*align-items: center;*/
  text-align: center;
  line-height: 92px;
  font-size: 32px;
  border: 2px solid #dcdcdc;
  border-radius: 3px;


}

.title {
  /*float: none;*/
  width: 100%;
  height: 62px;
  line-height: 62px;
  margin-bottom: 62px;
  font-weight:500;
}

.title p{
  float: left;
  margin-bottom: 0;
}

.title p{
  font-size: 45px;
  color: #000000;
}

.title p::before{
  content: '';
  display: block;
  float: left;
  width: 12px;
  height: 64px;
  border-radius: 5px;
  margin-right: 42px;
  margin-left: -50px;
  background-color: #ec414d;
}

/*.title p:nth-child(2){*/
/*  font-size: 45px;*/
/*  color: #ea434b;*/
/*}*/

/*.title p:nth-child(3){*/
/*  font-size: 45px;*/
/*  color: #000000;*/
/*}*/

.button_box{
  position: absolute;
  left: 1462px;
  bottom: 86px;
}

.formRequired p{
  width: 198px;
  height: 32px;
  float: left;
  font-size: 32px;
  /*line-height: 32px;*/
  /*margin-right: 70px;*/
  color: #222222;
}


.formRequired div{
  height: 80px;
  /*background-color: pink;*/
  margin-bottom: 38px;
  line-height: 80px;
}

.formRequired input{
  width: 1130px;
  height: 80px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.formRequired .start{
  height: 208px;
}

.formRequired .start div{
  float: left;
  margin-right: 68px;
}

.formRequired .start input{
  display: block;
  float: left;
  width: 214px;
  height: 80px;
  /*margin-right: 68px;*/
}



.formRequired .start div i{
  font-size: 32px;
  margin-left: 10px;
}



.supplement{
  height: 540px;
  margin-top: 16px;
}

.supplement .title{
  font-size: 45px;
  color: #000000;
  font-weight:500;
}

.formAddition div{
  /*height: 80px;*/
  /*background-color: pink;*/
  margin-bottom: 38px;
  line-height: 80px;
}

.formAddition p{
  float: left;
  width: 198px;
  height: 32px;
  /*line-height: 32px;*/
  font-size: 32px;
  color: #222222;
}

.formAddition input{
  width: 1130px;
  height: 80px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  /*background-color: pink;*/
}

.formAddition i{
  height: 80px;
  line-height: 80px;
  font-size: 32px;
  color: #222222;
  margin-left: 3px;
  margin-top: 2px;
}


.formAddition .demand input{
  width: 1130px;
  height: 214px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.formAddition .enclosure{
  /*float: left;*/
  /*width: 123px;*/
  height: 60px;
  margin-top: 10px;
  margin-bottom: 0;
}

.formAddition .enclosure button{
  width: 123px;
  height: 60px;
  background-color: #ff645f;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
}

.standard{
  width: 1130px;
  height: 320px;
  margin-bottom: 54px;
  /*background-color: pink;*/
}

.standard div:nth-of-type(1){
  width: 1130px;
  /*width: 1130px;*/
  height: 32px;
  color: #212121;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 33px;
}


.standard .box1{
  width: 1130px;
  height: 78px;
  margin-bottom: 38px;
  /*background-color: purple;*/
}

.standard .box1 h1{
  width: 1130px;
  height: 25px;
  color: #212121;
  font-size: 25px;
  line-height: 25px;
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 30px;
}

.standard .box1 input{
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 5px;
}

.standard .box1 .choice {
  width: 1130px;
  height: 22px;
  line-height: 22px;
}

.standard .box1 .choice i{
  display: block;
  float: left;
  height: 22px;
  /*margin-top: 3px;*/
  line-height: 22px;
  font-size: 22px;
  margin-right: 8px;
}

.box1 h2{
  width: 1130px;
  height: 16px;
  line-height: 16px;
  margin-top: 8px;
  font-weight: 400;
  color: #212121;
}



.button_box button{
  width: 193px;
  height: 68px;
  font-size: 24px;
  font-weight: 500;
  /*margin-top: 32px;*/
  text-align: center;
  line-height: 68px;
  color: #fef6f6;
  background-color: #ec414d;
  /*border: 1px solid transparent;*/
  border-radius: 3px;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all linear .7s;
  margin-right: 35px;
}

.button_box button:hover{
  background-size: 300%;
}

.checkbox{
  --el-checkbox-checked-background-color: rgb(235,66,77);
  --el-checkbox-checked-font-color: rgb(235,66,77);
  --el-checkbox-checked-input-border-color:rgb(235,66,77);
}
</style>
